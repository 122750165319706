import React, { useContext } from "react";
import cartContext from "../../contexts/cart/cartContext";
import { displayMoney } from "../../helpers/utils";
import useActive from "../../hooks/useActive";

const ProductCard = (props) => {
  const { id, image_url, title, description, final_price } = props;

  const { addItem } = useContext(cartContext);
  const { active, handleActive, activeClass } = useActive(false);

  // handling Add-to-cart
  const handleAddItem = () => {
    // const item = { ...props };
    // addItem(item);

    // handleActive(id);

    // setTimeout(() => {
    //   handleActive(false);
    // }, 3000);

    const whatsappUrl = `https://api.whatsapp.com/send?phone=+916284347764&text=${encodeURIComponent(
      "Check out this image!"
    )} ${encodeURIComponent(image_url)}`;
    window.open(whatsappUrl, "_blank");
  };

  const newPrice = displayMoney(final_price);


  return (
    <a href={image_url} target="_blank">
      <div className="card products_card">
        <figure className="products_img">
          <img src={image_url} alt="product-img" />
          {/* <Link to={`${path}${id}`}>
            
          </Link> */}
        </figure>
        <div className="products_details">
          {/* <span className="rating_star">
            {[...Array(rateCount)].map((_, i) => (
              <IoMdStar key={i} />
            ))}
          </span> */}
          <h3 className="products_title">
            {title}
            {/* <Link to={`${path}${id}`}>{title}</Link> */}
          </h3>
          <h5 className="products_info">{description}</h5>
          <div className="separator"></div>
          <h2 className="products_price">
            {newPrice} &nbsp;
            {/* <small>
              <del>{oldPrice}</del>
            </small> */}
          </h2>
          <button
            type="button"
            className={`btn products_btn ${activeClass(id)}`}
            onClick={handleAddItem}
          >
            {active ? "Added" : "Order"}
          </button>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
