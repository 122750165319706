import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../FirebaseConfig";

const useCategory = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getFeaturedCategories() {
      try {
        getDocs(collection(firestore, "/categories")).then(querySnapshot => {
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }));
          setCategories(newData);
        })
      }
      catch (e) {
        console.log(e)
      }
    }
    getFeaturedCategories();
  }, []);
  return { categories }
}

export default useCategory;
