import React, { useState } from "react";
import "./AddProductPage.css"; // Import your CSS file for styling
import { firestore, storage } from "../FirebaseConfig";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";

function AddProductPage() {
  const [imageUrl, setImageUrl] = useState();
  // State to manage form data
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    length: "",
    category_id: "2TQOldAEqoPwJAvuTd1j", // Default category
    imageFile: null, // To store the uploaded image file
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file input change (image upload)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      imageFile: file,
    });
  };

  // Handle form submission (for testing)
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload image to Firebase Storage
    const storageRef = ref(storage, formData.imageFile.name);
    await uploadBytes(storageRef, formData.imageFile);

    // Get image URL after upload
    const url = await getDownloadURL(storageRef);
    setImageUrl(url);

    const docRef = await addDoc(collection(firestore, "products"), {
      title: formData.title,
      final_price: formData.price,
      image_url: url,
      length: formData.length,
      category_id: formData.category_id,
    });

    // Log form data and image URL
    // console.log("Form Data:", formData);
    // console.log("Image URL:", url);
    setFormData({
        title: formData.title,
        price: "",
        length: formData.length,
        category_id: formData.category_id, // Default category
        imageFile: null, // To store the uploaded image file
      })
  };

  return (
    <div className="add_products_container-wrapper dark-theme">
      <div className="add_products_container">
        <h1 className="dark-theme">Product Details</h1>
        <form onSubmit={handleSubmit} className="dark-theme">
          <div className="product-image">
            <label htmlFor="image" className="dark-theme">
              Upload Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleFileChange}
              className="dark-theme"
            />
            {formData.imageFile && (
              <img
                src={URL.createObjectURL(formData.imageFile)}
                alt="Uploaded Preview"
                style={{
                  maxWidth: "100%",
                  marginTop: "10px",
                  borderRadius: "8px",
                }}
              />
            )}
          </div>

          <div className="input-fields">
            <label htmlFor="title" className="dark-theme">
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              placeholder="Product Title"
              value={formData.title}
              onChange={handleInputChange}
              className="dark-theme"
            />

            <label htmlFor="price" className="dark-theme">
              Price:
            </label>
            <input
              type="text"
              id="price"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleInputChange}
              className="dark-theme"
            />

            <label htmlFor="length" className="dark-theme">
              Length:
            </label>
            <input
              type="text"
              id="length"
              name="length"
              placeholder="Length"
              value={formData.length}
              onChange={handleInputChange}
              className="dark-theme"
            />
            <label htmlFor="category_id" className="dark-theme">
              Category_id:
            </label>
            <input
              type="text"
              id="category_id"
              name="category_id"
              placeholder="Product Category"
              value={formData.category}
              onChange={handleInputChange}
              className="dark-theme"
            />
          </div>

          <button type="submit" className="dark-theme">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddProductPage;
