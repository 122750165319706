import { useEffect, useState } from "react";
import { firestore } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const useProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getProducts() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "/products"));
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log(data);
        setProducts(data);
      } catch (e) {
        console.log(e);
      }
    }
    getProducts();
  }, []);

  return { products };
};

export default useProducts;
