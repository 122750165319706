import React, { useEffect, useState } from "react";
import useCategory from "../../hooks/useCategory";
import SectionsHead from "../common/SectionsHead";
import useProducts from "../../hooks/useProducts";
import ProductCard from "../product/ProductCard";

const TopProducts = () => {
  const { categories } = useCategory();
  const { products } = useProducts();
  const [productsWithCategories, setProductsWithCategories] = useState(
    new Map()
  );

  useEffect(() => {
    console.log("prods", products);
    console.log("cats", categories);

    async function getProductsWithCategories() {
      const productsByCategoryMap = new Map();

      categories.forEach((category) => {
        productsByCategoryMap.set(category.id, []);
      });

      products.forEach((product) => {
        if (productsByCategoryMap.has(product.category_id)) {
          productsByCategoryMap.get(product.category_id).push(product);
        }
      });
      console.log("prodsWithCats", productsByCategoryMap);
      setProductsWithCategories(productsByCategoryMap);
    }
    getProductsWithCategories();
  }, [categories, products]);

  return (
    <div className="top-products">
      {categories.map((category) => {
        if (productsWithCategories.get(category.id) && productsWithCategories.get(category.id).length >= 1) {
          return (<div key={category.id}>
            <SectionsHead heading={category.title} />
            <div className="products_wrapper" >
            {productsWithCategories.get(category.id)
              ? productsWithCategories
                  .get(category.id)
                  .map((product) => (
                    <ProductCard key={product.id} {...product} />
                  ))
              : []}
            </div>
          </div>)
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default TopProducts;
