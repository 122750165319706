import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import commonContext from "../../contexts/common/commonContext";
import useForm from "../../hooks/useForm";
import useOutsideClose from "../../hooks/useOutsideClose";
import useScrollDisable from "../../hooks/useScrollDisable";
import { useAuth } from "../../contexts/AuthContext";
import { RecaptchaVerifier } from "firebase/auth";
import { auth as FirebaseAuth } from "../../FirebaseConfig";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const AccountForm = () => {
  const { isFormOpen, toggleForm } = useContext(commonContext);
  const { inputValues, handleInputValues, handleFormSubmit } = useForm();

  const [recaptcha, setRecaptcha] = useState(null);
  const auth = useAuth();

  const formRef = useRef();

  // useEffect(() => {
  //   // Check if FirebaseAuth is valid
  //   if (!FirebaseAuth) {
  //     console.error("FirebaseAuth instance is not valid.");
  //     return;
  //   }

  //   // Check if the captcha-container element exists
  //   if (!document.getElementById("captcha-container")) {
  //     console.error(
  //       "Element with ID 'captcha-container' does not exist in the DOM."
  //     );
  //     return;
  //   }

  //   if (!recaptcha) {
  //     const verifier = new RecaptchaVerifier(
  //       FirebaseAuth,
  //       "captcha-container",
  //       {
  //         size: "invisible",
  //       }
  //     );
  //     setRecaptcha(verifier);
  //   }

  //   return () => {
  //     if (recaptcha) {
  //       recaptcha.clear();
  //       setRecaptcha(null);
  //     }
  //   };
  // }, [recaptcha]);

  // useEffect(() => {
  //   if (recaptcha) {
  //     window.recaptchaVerifier = recaptcha;

  //     recaptcha.render().then((widgetId) => {
  //       window.recaptchaWidgetId = widgetId;
  //     });
  //   }
  // }, [recaptcha]);

    useOutsideClose(formRef, () => {
      toggleForm(false);
    });

  useScrollDisable(isFormOpen);

  const [isSignupVisible, setIsSignupVisible] = useState(false);

  // Signup-form visibility toggling
  const handleIsSignupVisible = () => {
    setIsSignupVisible((prevState) => !prevState);
  };

  const onSendOtpClicked = () => {};

  const handlePhoneFieldChange = () => {};

  return (
    <>
      {isFormOpen && (
        <div className="backdrop">
          <div className="modal_centered">
            <div className="modal_container">
              <Logo height={100} width={100} />
              <span className="sign-in-text">Sign In With Phone Number</span>
              <form ref={formRef} className="form" onSubmit={onSendOtpClicked}>
                <input
                  type="text"
                  placeholder="+XX XXXXXXXXXX"
                  className="field"
                  onChange={handlePhoneFieldChange}
                />
                <button type="submit" id="signInButton" className="button">
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountForm;
