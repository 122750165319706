import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithPhoneNumber,
  onAuthStateChanged,
  signInWithCredential,
  PhoneAuthProvider,
} from "firebase/auth";

import { auth } from "../FirebaseConfig";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user : null);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  async function sendVerificationCode(phoneNumber) {
    let appVerifier = window.recaptchaVerifier;
    return await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
  }

  function verifyOtpAndSignInUser(otp) {
    let verificationId = window.confirmationResult.verificationId;
    let credential = PhoneAuthProvider.credential(verificationId, otp);

    return signInWithCredential(auth, credential);
  }

  const value = {
    currentUser,
    sendVerificationCode,
    verifyOtpAndSignInUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
