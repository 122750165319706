import React from "react";
import { Link } from "react-router-dom";
import { footMenu, footSocial } from "../../data/footerData";

const Footer = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappUrl = `https://api.whatsapp.com/send?phone=+916284347764&text=Hi, Join me on WhatsApp Group`;
    window.open(whatsappUrl, "_blank");
  };

  const currYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className="container">
        <div className="wrapper footer_wrapper">
          <div className="foot_about">
            <h2>
              <Link to="/">Goyal Lace Point</Link>
            </h2>
            <div className="foot_subs">
              <p>
                Join our WhatsApp Group to receive early discount offers, and
                new products info.
              </p>
              <form onSubmit={handleSubmit}>
                <button type="submit" className="btn">
                  Join
                </button>
              </form>
            </div>
          </div>

          {footMenu.map((item) => {
            const { id, title, menu } = item;
            return (
              <div className="foot_menu" key={id}>
                <h4>{title}</h4>
                <ul>
                  {menu.map((item) => {
                    const { id, link, path } = item;
                    return (
                      <li key={id}>
                        <Link to={path}>{link}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>

      <div className="separator"></div>

      <div className="sub_footer">
        <div className="container">
          <div className="sub_footer_wrapper">
            <div className="foot_copyright">
              <p>
                {currYear} | Goyal Traders. All Rights Reserved. Built by |{" "}
                <a href="https://www.github.com/vaibhavgoyal09">
                  Vaibhav Goyal
                </a>
              </p>
            </div>
            <div className="foot_social">
              {footSocial.map((item) => {
                const { id, icon, path } = item;
                return (
                  <a
                    href={path}
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {icon}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
