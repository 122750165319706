import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBT4dfX8vfgNQrECJ-scJuuNkiKXHVJ2cU",
  authDomain: "lace-store.firebaseapp.com",
  projectId: "lace-store",
  storageBucket: "lace-store.appspot.com",
  messagingSenderId: "694304972654",
  appId: "1:694304972654:web:9d65394ac9e50753ce6b7f",
  measurementId: "G-6W37ESJ5B9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { auth, storage, firestore };
