import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../FirebaseConfig";

const useHeroItems = () => {
  const [heroItems, setHeroItems] = useState([]);

  useEffect(() => {
    async function getHeroItems() {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "hero_items")
        );
        const items = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setHeroItems(items);
      } catch (e) {
        console.log(e);
      }
    }
    getHeroItems();
  }, []);
  return heroItems;
};

export default useHeroItems;
